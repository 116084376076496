export default function End(parms){
    return(
        <div style={{textAlign:"center", width: "100vw"}}>
            <div className="icon icon-crossed" style={{margin:"auto"}}></div>
            <h2>Thanks for Submitting Your Project</h2>
            <p>We'll call you in the next 24 hours to discuss about your project and schedule a meeting.</p>
            
            <a href="https://purplez.com/" onClick={parms.handleSetStep} id="3" className="back">Visit Website</a><button onClick={parms.handleSetStep} id="0" style={{width:"200px"}}>Take a new Journy</button>
        </div>
    );
}