import { Input, Radiobox, Checkbox } from "../../../components/input";

export default function Step3(parms){
    return(<div>
        <div className="icon icon-writing"></div>
        <h2>Marketing</h2>
        <p>Marketing information about your business.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.content === "") ? true : false} title="What type of content would you like to be shared on your social media?" placeholder="Please provide a sample." value={parms.content} handler={parms.handleSetContent} textarea full/>
            <Input error={(parms.error !== "" && parms.target === "") ? true : false} title="Who is your target market? " placeholder="Clearly define your demographics [age, gender, location, geography]. We may create segments organized by multiple attributes for a broad target market." value={parms.target} handler={parms.handleSetTarget} textarea/>
            <Input error={(parms.error !== "" && parms.cities === "") ? true : false} title="Target cities and zip codes" value={parms.cities} handler={parms.handleSetCities} textarea col2/>
            <Input error={(parms.error !== "" && parms.competition === "") ? true : false} title="Who is your competition?" value={parms.competition} handler={parms.handleSetCompetition} textarea/>
            <Input title="What have you done for your marketing so far?" value={parms.marketing} handler={parms.handleSetMarketing} textarea col2/>
            <Input title="What marketing message you think works for your business?" value={parms.message} handler={parms.handleSetMessage} full/>
        </div>
        <span onClick={parms.handleSetStep} id="2" className="back">Back</span><button onClick={parms.handleSetStep} id="4">Next Step</button>
    </div>);
}