import { Input, Radiobox, Checkbox } from "../../../components/input";

export default function Step3(parms){
    return(<div>
        <div className="icon icon-writing"></div>
        <h2>Design</h2>
        <p>Help us to choose the best design system for yout industry.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.color1 === "") ? true : false} title="Brand color #1" value={parms.color1} handler={parms.handleSetColor1} color/>
            <Input title="Brand color #2" value={parms.color2} handler={parms.handleSetColor2} color/>
            <Input title="Brand color #3" value={parms.color3} handler={parms.handleSetColor3} color col2/>
            <Checkbox title="What features will your website need?" name="features" items={[
                {title:"Forms", value:parms.fForm, handler:parms.handleSetFForm},
                {title:"ecommerce", value:parms.fEcommerce, handler:parms.handleSetFEcommerce},
                {title:"Maps", value:parms.fMap, handler:parms.handleSetFMap},
                {title:"Social media buttons", value:parms.fSocialMedia, handler:parms.handleSetFSocialMedia},
                {title:"Click-to-call buttons", value:parms.fCTC, handler:parms.handleSetFCTC},
                {title:"Online ordering / eCommerce", value:parms.fOnlineOrder, handler:parms.handleSetFOnlineOrder},
                {title:"Search", value:parms.fSearch, handler:parms.handleSetFSearch},
                {title:"Portfolio / gallery", value:parms.fPortfolio, handler:parms.handleSetFPortfolio},
                {title:"Pricing tables", value:parms.fPricing, handler:parms.handleSetFPricing},
                {title:"Calls-to-action", value:parms.fCTA, handler:parms.handleSetFCTA},
                {title:"Forum", value:parms.fForum, handler:parms.handleSetFForum},
                {title:"Others", value:parms.fOthers, handler:parms.handleSetFOthers}
            ]}/>
        </div>
        <h2>Development</h2>
        <p>Provide some development information.</p>
        <div className="form-container">
            <Radiobox title="Do you need hosting?" items={[{title:"Yes", value: true},{title:"No", value: false}]} name="hosting" value={parms.hosting} handler={parms.handleSetHosting}/>
            <Radiobox title="Will the old site be moved to a new location?" items={[{title:"Yes", value: true},{title:"No", value: false}]} name="migration" value={parms.migration} handler={parms.handleSetMigration}/>
            <Radiobox title="Do you want us to handle maintenance?" items={[{title:"Yes", value: true},{title:"No", value: false}]} name="maintenance" value={parms.maintenance} handler={parms.handleSetMaintenance} col2/>
        </div>
        <span onClick={parms.handleSetStep} id="2" className="back">Back</span><button onClick={parms.handleSetStep} id="4">Next Step</button>
    </div>);
}