import { Input } from "../../../components/input";

export default function Step4(parms){
    return(<div>
        <div className="icon icon-call"></div>
        <h2>Contact Info</h2>
        <p>We'll use this information on your website and we'll call you back soon.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.phone === "") ? true : false} title="Phone Number(s)" placeholder="+1 (800) 213-7303" value={parms.phone} handler={parms.handleSetPhone}/>
            <Input error={(parms.error !== "" && parms.email === "") ? true : false} title="Email Address" placeholder="info@purplez.com" value={parms.email} handler={parms.handleSetEmail} col2/>
            <Input error={(parms.error !== "" && parms.adderss === "") ? true : false} title="Address" placeholder="2372 Morse Ave, Suite 389, Irvine, CA" value={parms.adderss} handler={parms.handleSetAddress} full/>
            <Input title="Mailing Address(s)" value={parms.mailing} handler={parms.handleSetMailing}/>
            <Input error={(parms.error !== "" && parms.zipcode === "") ? true : false} title="Zipcode" placeholder="92618" value={parms.zipcode} handler={parms.handleSetZipcode} col2/>
            <Input title="Any other information that you whant to share with us" textarea value={parms.otherInfo} handler={parms.handleSetOtherInfo} full/>
        </div>
        <span onClick={parms.handleSetStep} id="4" className="back">Back</span>{!parms.loading ? <button onClick={parms.submit} id="6">Finish</button> : <button>Please Wait</button>}
        <p className="error">{parms.error}</p>
    </div>);
}