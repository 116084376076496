export function Input(parms){
    
    return(
    <div className={((parms.col2 || parms.full) ? "input-container col2" : "input-container") + " " + (parms.full && "full") + " " + (parms.color && "color")}>
        <span className="input-title">{parms.title}</span>
        {parms.textarea ?
            <textarea placeholder={parms.placeholder} className={parms.error && "redoutline"} value={parms.value} onChange={parms.handler}/>
        : parms.color ?
            <input type="color" placeholder={parms.placeholder} className={parms.error && "redoutline"} value={parms.value} onChange={parms.handler}/>
        :
            <input type="text" placeholder={parms.placeholder} className={parms.error && "redoutline"} value={parms.value} onChange={parms.handler}/>
        }
    </div>
    );
}

export function Checkbox(parms){
    return(
    <div className="input-container col2 full">
        <span className="input-title">{parms.title}</span>
        {parms.items.map((item, i) => {
            return(
            <label className="chackbox" key={i}>
                <input type="checkbox" name={parms.name} checked={item.value} onChange={item.handler}/>
                <div className="checkbox-box"></div>
                <span>{item.title}</span>
            </label>
            );
        })}
    </div>
    );
}

export function Radiobox(parms){
    return(
    <div className={!parms.col2 ? "input-container radio" : "input-container col2 radio"}>
        <span className="input-title">{parms.title}</span>
        {parms.items.map((item, i) => {
            return(
            <label className="radiobox" key={i}>
                <input type="radio" name={parms.name} value={item.value} checked={parms.value === item.value} onChange={parms.handler}/>
                <div className="radiobox-box"></div>
                <span>{item.title}</span>
            </label>
            );
        })}
    </div>
    );
}