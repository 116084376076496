import { Input, Radiobox } from "../../../components/input";

export default function Step3(parms){
    return(<div>
        <div className="icon icon-writing"></div>
        <h2>Content Source</h2>
        <p>This information helps us know your brand and decide the bests for you.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input title="Facebook Link" placeholder="https://www.facebook.com/purpleZ.Inc/" value={parms.facebook} handler={parms.handleSetFacebook}/>
            <Input title="Youtube Link" placeholder="https://www.youtube.com/channel/UCDh86ba-JH67G7oY11M1Haw" value={parms.youtube} handler={parms.handleSetYoutube} col2/>
            <Input title="Instagram Link" placeholder="https://www.instagram.com/purplez.inc/" value={parms.instagram} handler={parms.handleSetInstagram}/>
            <Input title="Yelp Link" placeholder="https://www.yelp.com/biz/purplez-irvine" value={parms.yelp} handler={parms.handleSetYelp} col2/>
            <Input title="Other Social Networks" placeholder="e.g. (https://www.linkedin.com/company/purplez) (https://twitter.com/purplezco)" value={parms.otherLinks} handler={parms.handleSetOtherLinks} textarea/>
            <Radiobox title="Who will provide content for the new website?" items={[{title:"Me", value:"client"},{title:"Purplez Content Team", value:"purplez"}]} name="content" value={parms.content} handler={parms.handleSetContent} col2/>
        </div>
        <span onClick={parms.handleSetStep} id="3" className="back">Back</span><button onClick={parms.handleSetStep} id="5">Next Step</button>
    </div>);
}