import React from "react";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import End from "./end";
import { post } from "../../Moudles/axios";
import { Link } from "react-router-dom";
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";

export default function Onboarding(){

    // Variables Defination
    const [step, setStep] = React.useState(0);
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);

        // Step 1 - variables
    const [name, setName] = React.useState("");
    const [DBA, setDBA] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");

        // Step 2 - variables
    const [apart, setApart] = React.useState("");
    const [process, setProcess] = React.useState("");
    const [promotions, setPromotions] = React.useState("");
    const [service, setService] = React.useState("");
    const [goal, setGoal] = React.useState("");
    const [objectives, setObjectives] = React.useState("");
    const [budget, setBudget] = React.useState("1000>");

        // Step 3 - variables
    const [content, setContent] = React.useState("");
    const [target, setTarget] = React.useState("");
    const [cities, setCities] = React.useState("");
    const [competition, setCompetition] = React.useState("");
    const [marketing, setMarketing] = React.useState("");
    const [message, setMessage] = React.useState("");

        // Step 4 - variables
    const [logo, setLogo] = React.useState("");
    const [font, setFont] = React.useState("");
    const [material, setMaterial] = React.useState("");
    const [color1, setColor1] = React.useState("");
    const [color2, setColor2] = React.useState("");
    const [color3, setColor3] = React.useState("");

        // Step 5 - variables
    const [hosting, setHosting] = React.useState("");
    const [websiteLogin, setWebsiteLogin] = React.useState("");
    const [facebook, setFacebook] = React.useState("");
    const [instagram, setInstagram] = React.useState("");
    const [twitter, setTwitter] = React.useState("");
    const [youtube, setYoutube] = React.useState("");
    const [linkedin, setLinkedin] = React.useState("");
    const [pinterest, setPinterest] = React.useState("");
    const [google, setGoogle] = React.useState("");
    const [other, setOther] = React.useState("");

    // Variables Handlers
    function handleSetStep(e){
        setStep(e.target.id);
        handleScroll();
    }

        // Step 1 - Handlers
    function handleSetName(e){
        setName(e.target.value);
    }
    function handleSetDBA(e){
        setDBA(e.target.value);
    }
    function handleSetWebsite(e){
        setWebsite(e.target.value);
    }
    function handleSetPhone(e){
        setPhone(e.target.value);
    }
    function handleSetEmail(e){
        setEmail(e.target.value);
    }

        // Step 2 - Handlers
    function handleSetApart(e){
        setApart(e.target.value);
    }
    function handleSetProcess(e){
        setProcess(e.target.value);
    }
    function handleSetPromotions(e){
        setPromotions(e.target.value);
    }
    function handleSetService(e){
        setService(e.target.value);
    }
    function handleSetGoal(e){
        setGoal(e.target.value);
    }
    function handleSetObjectives(e){
        setObjectives(e.target.value);
    }
    function handleSetBudget(e){
        setBudget(e.target.value);
    }

        // Step 3 - Handlers
    function handleSetContent(e){
        setContent(e.target.value);
    }
    function handleSetTarget(e){
        setTarget(e.target.value);
    }
    function handleSetCities(e){
        setCities(e.target.value);
    }
    function handleSetCompetition(e){
        setCompetition(e.target.value);
    }
    function handleSetMarketing(e){
        setMarketing(e.target.value);
    }
    function handleSetMessage(e){
        setMessage(e.target.value);
    }

        // Step 4 - Handlers
    function handleSetLogo(e){
        setLogo(e.target.value);
    }
    function handleSetFont(e){
        setFont(e.target.value);
    }
    function handleSetMaterial(e){
        setMaterial(e.target.value);
    }
    function handleSetColor1(e){
        setColor1(e.target.value);
    }
    function handleSetColor2(e){
        setColor2(e.target.value);
    }
    function handleSetColor3(e){
        setColor3(e.target.value);
    }

        // Step 5 - Handlers
    function handleSetHosting(e){
        setHosting(e.target.value);
    }
    function handleSetWebsiteLogin(e){
        setWebsiteLogin(e.target.value);
    }
    function handleSetFacebook(e){
        setFacebook(e.target.value);
    }
    function handleSetInstagram(e){
        setInstagram(e.target.value);
    }
    function handleSetTwitter(e){
        setTwitter(e.target.value);
    }
    function handleSetYoutube(e){
        setYoutube(e.target.value);
    }
    function handleSetLinkedin(e){
        setLinkedin(e.target.value);
    }
    function handleSetPinterest(e){
        setPinterest(e.target.value);
    }
    function handleSetGoogle(e){
        setGoogle(e.target.value);
    }
    function handleSetOther(e){
        setOther(e.target.value);
    }

    function submit(){
        if(loading) return null;
        setLoading(true);

        if(name === "" || DBA === "" || email === "" || phone === ""){
            setError(" ");
            setStep(1);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(apart === "" || process === "" || service === "" || goal === ""){
            setError(" ");
            setStep(2);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(content === "" || target === "" || cities === "" || competition === ""){
            setError(" ");
            setStep(3);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(color1 === ""){
            setError(" ");
            setStep(4);
            handleScroll();
            setLoading(false);
            return null;
        }
        setError("");

        let data={
            "name":name,
            "DBA":DBA,
            "website":website,
            "phone":phone,
            "email":email,
            "apart":apart,
            "process":process,
            "promotions":promotions,
            "service":service,
            "goal":goal,
            "objectives":objectives,
            "budget":budget,
            "content":content,
            "target":target,
            "cities":cities,
            "competition":competition,
            "marketing":marketing,
            "message":message,
            "logo":logo,
            "font":font,
            "material":material,
            "color1":color1,
            "color2":color2,
            "color3":color3,
            "hosting":hosting,
            "websiteLogin":websiteLogin,
            "facebook":facebook,
            "instagram":instagram,
            "twitter":twitter,
            "youtube":youtube,
            "linkedin":linkedin,
            "pinterest":pinterest,
            "google":google,
            "other":other
        }
    
        function handleSubmit(status, response){
            setLoading(false);
            if(status){
                if(response.code === 200){
                    setStep(6);
                    handleScroll()
                }else{
                    setError("Unknown Error.")
                }
            }else{
                setError("There was a problem submiting the form. Please try again later.");
            }
        }
    
        post("https://form.purplez.com/onboarding.php", data, handleSubmit)
    }

    const mainelement = document.getElementsByClassName('main')[0];
    function handleScroll(){
        if (mainelement) {
            mainelement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    var nodeRef = React.createRef(null);

    function NavbarTop(){

        if(step == 0){
            return(
                <div ref={nodeRef}>
                    <h2>Purplez New Client Onboarding Form</h2>
                    <p>Follow the steps to complete the form.</p>
                </div>);
        } else if(step == 1){
            return(
                <div ref={nodeRef}>
                    <h2>Step 1</h2>
                    <p>Your business information is the first step to getting to know you.</p>
                </div>);
        } else if(step == 2){
            return(
                <div ref={nodeRef}>
                    <h2>Step 2</h2>
                    <p>Enter more details about your business and processes.</p>
                </div>);
        } else if(step == 3){
            return(
                <div ref={nodeRef}>
                    <h2>Step 3</h2>
                    <p>In this section, you will provide us with your marketing goals.</p>
                </div>);
        } else if(step == 4){
            return(
                <div ref={nodeRef}>
                    <h2>Step 4</h2>
                    <p>We will use this information to create a specific design system for your business.</p>
                </div>);
        } else if(step == 5){
            return(
                <div ref={nodeRef}>
                    <h2>Step 5</h2>
                    <p>Information about your accounts such as hosting and social networks.</p>
                </div>);
        } else {
            return(
                <div ref={nodeRef}>
                    <h2>Completed</h2>
                    <p>You have completed all the steps.</p>
                </div>);
        }
        
    }

    return(<div className="form">
        <Helmet>
            <title>New Client Onboarding - Purplez</title>
            <meta name="description" content="PurpleZ New Client Onboarding Form" />
        </Helmet>
        <div className="sidebar">
            <Link to="/" className="logo"/>
            <div className="navbar">
                <SwitchTransition>
                    <CSSTransition in={step} nodeRef={nodeRef} key={step} classNames="navtop" timeout={{enter: 200,exit: 200}}>
                        <NavbarTop/>
                    </CSSTransition>
                </SwitchTransition>
                <ul>
                    {step != 6 ? <li onClick={handleSetStep} id="1" className={step==0 ? "navbar-first" : "navbar-first active"}><span>1</span>Business Information</li> : <li id="1" className={step==0 ? "navbar-first" : "navbar-first active"}><span>1</span>Business Details</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="2" className={step>1 && "active"}><span>2</span>Business Details</li>: <li id="2" className={step>1 && "active"}><span>2</span>Marketing</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="3" className={step>2 && "active"}><span>3</span>Marketing</li>: <li id="3" className={step>2 && "active"}><span>3</span>Design and Media</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="4" className={step>3 && "active"}><span>4</span>Design and Media</li>: <li id="4" className={step>3 && "active"}><span>4</span>Accounts</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="5" className={step>4 && "active"}><span>5</span>Accounts</li>: <li id="5" className={step>3 && "active"}><span>4</span>Accounts</li>}
                </ul>
            </div>
        </div>
        <div className="main">
            <SwitchTransition>
                <CSSTransition key={step} classNames="main" timeout={{enter: 200,exit: 200}}>
                    {step == 0?
                        <Main handleSetStep={handleSetStep}/>
                    : step == 1?
                        <Step1
                            handleSetStep={handleSetStep}
                            name={name} handleSetName={handleSetName}
                            DBA={DBA} handleSetDBA={handleSetDBA}
                            website={website} handleSetWebsite={handleSetWebsite}
                            phone={phone} handleSetPhone={handleSetPhone}
                            email={email} handleSetEmail={handleSetEmail}
                            error={error}/>
                    : step == 2?
                        <Step2 
                            handleSetStep={handleSetStep}
                            apart={apart} handleSetApart={handleSetApart}
                            process={process} handleSetProcess={handleSetProcess}
                            promotions={promotions} handleSetPromotions={handleSetPromotions}
                            service={service} handleSetService={handleSetService}
                            goal={goal} handleSetGoal={handleSetGoal}
                            objectives={objectives} handleSetObjectives={handleSetObjectives}
                            budget={budget} handleSetBudget={handleSetBudget}
                            error={error}/>
                    : step == 3?
                        <Step3
                            handleSetStep={handleSetStep}
                            content={content} handleSetContent={handleSetContent}
                            target={target} handleSetTarget={handleSetTarget}
                            cities={cities} handleSetCities={handleSetCities}
                            competition={competition} handleSetCompetition={handleSetCompetition}
                            marketing={marketing} handleSetMarketing={handleSetMarketing}
                            message={message} handleSetMessage={handleSetMessage}
                            error={error}/>
                    : step == 4?
                        <Step4
                            handleSetStep={handleSetStep}
                            logo={logo} handleSetLogo={handleSetLogo}
                            font={font} handleSetFont={handleSetFont}
                            material={material} handleSetMaterial={handleSetMaterial}
                            color1={color1} handleSetColor1={handleSetColor1}
                            color2={color2} handleSetColor2={handleSetColor2}
                            color3={color3} handleSetColor3={handleSetColor3}
                            error={error}/>
                    : step == 5?
                        <Step5
                        handleSetStep={handleSetStep}
                        hosting={hosting} handleSetHosting={handleSetHosting}
                        websiteLogin={websiteLogin} handleSetWebsiteLogin={handleSetWebsiteLogin}
                        facebook={facebook} handleSetFacebook={handleSetFacebook}
                        Instagram={instagram} handleSetInstagram={handleSetInstagram}
                        twitter={twitter} handleSetTwitter={handleSetTwitter}
                        youtube={youtube} handleSetYoutube={handleSetYoutube}
                        linkedin={linkedin} handleSetLinkedin={handleSetLinkedin}
                        pinterest={pinterest} handleSetPinterest={handleSetPinterest}
                        google={google} handleSetGoogle={handleSetGoogle}
                        otherInfo={other} handleSetOtherInfo={handleSetOther}
                        submit={submit}
                        loading={loading}
                        error={error}/>
                    :
                        <End handleSetStep={handleSetStep}/>
                    }
                </CSSTransition>
            </SwitchTransition>
        </div>
    </div>);
}

function Main(parms){
    return(<div>
        <div className="icon icon-waving"></div>
        <h1>Welcome To Purplez</h1>
        <p>In this form we are going to collect some important imformation about your brand so we can help you in the best way. Please help us By filling the whole fields to undrstand your project's requirements.</p>
        <p>Thanks.</p>
        <button onClick={parms.handleSetStep} id="1">Let's Go</button>
    </div>);
}