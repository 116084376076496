import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home(){
    return(<div className="home">
        <Helmet>
            <title>Purplez Forms Application</title>
            <meta name="description" content="PurpleZ Forms Application" />
        </Helmet>
        <div className="main-logo"/>
        <p>Welcome to PurpleZ Marketing Agency Forms Application</p>
        <h1>Choose the Form You Want to Start Your Journey with.</h1>
        <div className="home-cards">
            <div to="/website" className="home-card">
                <div className="home-card-image website"></div>
                <div className="home-card-title">Website Questionnaire</div>
                <div className="home-card-description">In this form we are going to collect some important imformation about your website project so we can develop it in the best way.</div>
                <div className="home-card-tag">Free</div>
                <Link to="/website" className="home-card-button">Start</Link>
            </div>
            <div to="/website" className="home-card">
                <div className="home-card-image onboarding"></div>
                <div className="home-card-title">New Client Onboarding</div>
                <div className="home-card-description">Is a document used to gather important information from new clients and ensure a smooth transition into the business relationship.</div>
                <div className="home-card-tag">Free</div>
                <Link to="/onboarding" className="home-card-button">Start</Link>
            </div>
            <div to="/website" className="home-card deactive">
                <div className="home-card-image marketing"></div>
                <div className="home-card-title">Marketing Audit</div>
                <div className="home-card-description">A marketing audit form is a tool used to assess a company's marketing efforts and identify areas for improvement.</div>
                <div className="home-card-tag">Comming Soon</div>
                <Link className="home-card-button">Start</Link>
            </div>
        </div>
        <footer>
            <Link to="https://purplez.com/">Powered with ☕ by <span>PurpleZ</span></Link>
            <div className="copyright">&copy; PurpleZ - 2023</div>
        </footer>
    </div>);
}