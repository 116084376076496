import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Website from './pages/website';
import Onboarding from './pages/onboarding'
import Home from './Home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: <Home />,
  },
  {
    path: "/website",
    element: <Website/>,
    errorElement: <Website />,
  },
  {
    path: "/onboarding",
    element: <Onboarding/>,
    errorElement: <Onboarding />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
