import { Input } from "../../../components/input";

export default function Step2(parms){
    return(<div>
        <div className="icon icon-victory"></div>
        <h2>Project Information</h2>
        <p>A summary of your project definition.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input title="What keywords will your audience use to find your website?" value={parms.keyword} handler={parms.handleSetKeyword} full/>
            <Input error={(parms.error !== "" && parms.url === "") ? true : false} title="Do you need a new URL?" placeholder="If so, please give us you preferred names." value={parms.url} handler={parms.handleSetUrl}/>
            <Input error={(parms.error !== "" && parms.deadline === "") ? true : false} title="What is the deadline for the website?" value={parms.deadline} handler={parms.handleSetDeadline} col2/>
            <Input error={(parms.error !== "" && parms.interests === "") ? true : false} title="What do you like about your website?" placeholder="Your response to this question will help us understand the website elements that mean the most to you. Defining the new website’s purpose, understanding its current weaknesses, and creating a detailed feature list will help us build a solid foundation for a successful project." value={parms.interests} handler={parms.handleSetInterests} textarea full/>
            <Input title="What similar websites do you like and what is it you like about them?" placeholder="This will show us what styles you like and provide examples of features that might be difficult to describe. It can be especially helpful for you to point out features you like on competitor sites." value={parms.similar} handler={parms.handleSetSimilar} textarea full/>
        </div>
        <span onClick={parms.handleSetStep} id="1" className="back">Back</span><button onClick={parms.handleSetStep} id="3">Next Step</button>
    </div>);
}