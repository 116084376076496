import { Input, Radiobox } from "../../../components/input";

export default function Step2(parms){
    return(<div>
        <div className="icon icon-victory"></div>
        <h2>Business Details</h2>
        <p>Fill in the fields below to help us understand your business process.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.apart === "") ? true : false} title="What is your value proposition?" placeholder="What is different and unique about your brand and sets you apart from the competition." value={parms.apart} handler={parms.handleSetApart} textarea/>
            <Input error={(parms.error !== "" && parms.process === "") ? true : false} title="Describe your main business processes" placeholder="Lead gen, marketing, sales" value={parms.process} handler={parms.handleSetProcess} textarea col2/>
            <Input title="What discounts/promotions can you offer?" value={parms.promotions} handler={parms.handleSetPromotions} textarea/>
            <Input error={(parms.error !== "" && parms.service === "") ? true : false} title="List all your products/services" value={parms.service} handler={parms.handleSetService} textarea col2/>
            <Input error={(parms.error !== "" && parms.goal === "") ? true : false} title="What is your business long term goal?" value={parms.goal} handler={parms.handleSetGoal} textarea/>
            <Input title="What are your objectives?" value={parms.objectives} handler={parms.handleSetObjectives} textarea col2/>
            <Radiobox title="How much is your paid advertisement budget?" items={[{title:"$100-$200/m", value:"100-200"},{title:"$200-$400/m", value:"200-400"},{title:"$400-$600/m", value:"400-600"},{title:"$600-$800/m", value:"600-800"},{title:"$1000/m>", value:"1000>"}]} name="budget" value={parms.budget} handler={parms.handleSetBudget}/>
        </div>
        <span onClick={parms.handleSetStep} id="1" className="back">Back</span><button onClick={parms.handleSetStep} id="3">Next Step</button>
    </div>);
}