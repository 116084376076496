import { Input } from "../../../components/input";

export default function Step4(parms){
    return(<div>
        <div className="icon icon-call"></div>
        <h2>Domain and Hosting</h2>
        <p>Please provide your Domain and Hosting information if you have any.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input title="Domain & Hosting usernames, passwords and providers" value={parms.hosting} handler={parms.handleSetHosting} textarea full/>
            <Input title="Please provide your website login information if applicable" value={parms.website} handler={parms.handleSetWebsite} textarea full/>
        </div>
        <h2>Social Media</h2>
        <p>Please provide your social media information.</p>
        <div className="form-container">
            <Input title="Facebook" placeholder="If you have a Facebook page please provide a link to it and give us (https://www.facebook.com/profile.php?id=100073154637498) access to it." value={parms.facebook} handler={parms.handleSetFacebook} textarea/>
            <Input title="Instagram" placeholder="Page URL, usename, password" value={parms.instagram} handler={parms.handleSetInstagram} textarea col2/>
            <Input title="Twitter" placeholder="Page URL, usename, password" value={parms.twitter} handler={parms.handleSetTwitter} textarea/>
            <Input title="YouTube" placeholder="Page URL, usename, password" value={parms.youtube} handler={parms.handleSetYoutube} textarea col2/>
            <Input title="Linkedin" placeholder="Page URL, usename, password" value={parms.linkedin} handler={parms.handleSetLinkedin} textarea/>
            <Input title="Pinterest" placeholder="Page URL, usename, password" value={parms.pinterest} handler={parms.handleSetPinterest} textarea col2/>
            <Input title="Does your business have a Google Business listing?" placeholder="If yes please provide a link to it and grant admin permission to purplezmarketing@gmail.com" value={parms.google} handler={parms.handleSetGoogle} textarea full/>
            <Input title="Is there any social media/platform that was not mentioned above?" placeholder="If yes please provide information" value={parms.other} handler={parms.handleSetOther} textarea full/>
        </div>
        <span onClick={parms.handleSetStep} id="4" className="back">Back</span>{!parms.loading ? <button onClick={parms.submit} id="6">Finish</button> : <button>Please Wait</button>}
        <p className="error">{parms.error}</p>
    </div>);
}