import React from "react";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import End from "./end";
import { post } from "../../Moudles/axios";
import { Link } from "react-router-dom";
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";

export default function Website(){

    // Variables Defination
    const [step, setStep] = React.useState(0);
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);

        // Step 1 - variables
    const [name, setName] = React.useState("");
    const [DBA, setDBA] = React.useState("");
    const [service, setService] = React.useState("");
    const [apart, setApart] = React.useState("");
    const [target, setTarget] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [whyNewWebsite, setWhyNewWebsite] = React.useState("");
    const [branding, setBranding] = React.useState("");

        // Step 2 - variables
    const [keyword, setKeyword] = React.useState("");
    const [url, setUrl] = React.useState("");
    const [deadline, setDeadline] = React.useState("");
    const [interests, setInterests] = React.useState("");
    const [similar, setSimilar] = React.useState("");

        // Step 3 - variables
    const [color1, setColor1] = React.useState("");
    const [color2, setColor2] = React.useState("");
    const [color3, setColor3] = React.useState("");
    const [logo, setLogo] = React.useState("");
            // Features = F
    const [fForm, setFForm] = React.useState(false);
    const [fEcommerce, setFEcommerce] = React.useState(false);
    const [fMap, setFMap] = React.useState(false);
    const [fSocialMedia, setFSocialMedia] = React.useState(false);
    const [fCTC, setFCTC] = React.useState(false); // Click-to-Call Buttons
    const [fOnlineOrder, setFOnlineOrder] = React.useState(false);
    const [fSearch, setFSearch] = React.useState(false);
    const [fPortfolio, setFPortfolio] = React.useState(false);
    const [fPricing, setFPricing] = React.useState(false); // Pricing Tables
    const [fCTA, setFCTA] = React.useState(false); // Call-to-Action
    const [fForum, setFForum] = React.useState(false);
    const [fOthers, setFOthers] = React.useState(false);

    const [hosting, setHosting] = React.useState(false);
    const [migration, setMigration] = React.useState(false);
    const [maintenance, setMaintenance] = React.useState(true);

        // Step 4 - variables
    const [facebook, setFacebook] = React.useState("");
    const [youtube, setYoutube] = React.useState("");
    const [instagram, setInstagram] = React.useState("");
    const [yelp, setYelp] = React.useState("");
    const [otherLinks, setOtherLinks] = React.useState("");
    const [content, setContent] = React.useState("purplez");

        // Step 5 - variables
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [adderss, setAddress] = React.useState("");
    const [mailing, setMailing] = React.useState(""); // Mailing Address(s)
    const [zipcode, setZipcode] = React.useState("");
    const [otherInfo, setOtherInfo] = React.useState("");

    // Variables Handlers
    function handleSetStep(e){
        setStep(e.target.id);
        handleScroll();
    }

        // Step 1 - Handlers
    function handleSetName(e){
        setName(e.target.value);
    }
    function handleSetDBA(e){
        setDBA(e.target.value);
    }
    function handleSetService(e){
        setService(e.target.value);
    }
    function handleSetApart(e){
        setApart(e.target.value);
    }
    function handleSetTarget(e){
        setTarget(e.target.value);
    }
    function handleSetWebsite(e){
        setWebsite(e.target.value);
    }
    function handleSetWhyNewWebsite(e){
        setWhyNewWebsite(e.target.value);
    }
    function handleSetBranding(e){
        setBranding(e.target.value);
    }

        // Step 2 - Handlers
    function handleSetKeyword(e){
        setKeyword(e.target.value);
    }
    function handleSetUrl(e){
        setUrl(e.target.value);
    }
    function handleSetDeadline(e){
        setDeadline(e.target.value);
    }
    function handleSetInterests(e){
        setInterests(e.target.value);
    }
    function handleSetSimilar(e){
        setSimilar(e.target.value);
    }

        // Step 3 - Handlers
    function handleSetColor1(e){
        setColor1(e.target.value);
    }
    function handleSetColor2(e){
        setColor2(e.target.value);
    }
    function handleSetColor3(e){
        setColor3(e.target.value);
    }
    function handleSetLogo(e){
        setLogo(e.target.value);
    }
            // Features = F
    function handleSetFForm(e){
        setFForm(e.target.cheched);
    }
    function handleSetFEcommerce(e){
        setFEcommerce(e.target.cheched);
    }
    function handleSetFMap(e){
        setFMap(e.target.cheched);
    }
    function handleSetFSocialMedia(e){
        setFSocialMedia(e.target.cheched);
    }
    function handleSetFCTC(e){
        setFCTC(e.target.cheched);
    }
    function handleSetFOnlineOrder(e){
        setFOnlineOrder(e.target.cheched);
    }
    function handleSetFSearch(e){
        setFSearch(e.target.cheched);
    }
    function handleSetFPortfolio(e){
        setFPortfolio(e.target.cheched);
    }
    function handleSetFPricing(e){
        setFPricing(e.target.cheched);
    }
    function handleSetFCTA(e){
        setFCTA(e.target.cheched);
    }
    function handleSetFForum(e){
        setFForum(e.target.cheched);
    }
    function handleSetFOthers(e){
        setFOthers(e.target.cheched);
    }
    
    function handleSetHosting(e){
        setHosting(e.target.value);
    }
    function handleSetMigration(e){
        setMigration(e.target.value);
    }
    function handleSetMaintenance(e){
        setMaintenance(e.target.value);
    }

        // Step 4 - Handlers
    function handleSetFacebook(e){
        setFacebook(e.target.value);
    }
    function handleSetYoutube(e){
        setYoutube(e.target.value);
    }
    function handleSetInstagram(e){
        setInstagram(e.target.value);
    }
    function handleSetYelp(e){
        setYelp(e.target.value);
    }
    function handleSetOtherLinks(e){
        setOtherLinks(e.target.value);
    }
    function handleSetContent(e){
        setContent(e.target.value);
    }

        // Step 5 - Handlers
    function handleSetPhone(e){
        setPhone(e.target.value);
    }
    function handleSetEmail(e){
        setEmail(e.target.value);
    }
    function handleSetAddress(e){
        setAddress(e.target.value);
    }
    function handleSetMailing(e){
        setMailing(e.target.value);
    }
    function handleSetZipcode(e){
        setZipcode(e.target.value);
    }
    function handleSetOtherInfo(e){
        setOtherInfo(e.target.value);
    }

    function submit(){
        if(loading) return null;
        setLoading(true);

        if(name === "" || DBA === "" || service === "" || apart === "" || website === "" || whyNewWebsite === "" || branding === ""){
            setError(" ");
            setStep(1);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(url === "" || deadline === "" || interests === ""){
            setError(" ");
            setStep(2);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(color1 === ""){
            setError(" ");
            setStep(3);
            handleScroll();
            setLoading(false);
            return null;
        }
        if(phone === "" || email === "" || adderss === "" || zipcode === ""){
            setError(" ");
            setStep(5);
            handleScroll();
            setLoading(false);
            return null;
        }
        setError("");

        let data={
            "name":name,
            "DBA":DBA,
            "service":service,
            "apart":apart,
            "target":target,
            "website":website,
            "whyNewWebsite":whyNewWebsite,
            "branding":branding,
            "keyword":keyword,
            "url":url,
            "deadline":deadline,
            "interests":interests,
            "similar":similar,
            "color1":color1,
            "color2":color2,
            "color3":color3,
            "logo":logo,
            "fForm":fForm,
            "fEcommerce":fEcommerce,
            "fMap":fMap,
            "fSocialMedia":fSocialMedia,
            "fCTC":fCTC,
            "fOnlineOrder":fOnlineOrder,
            "fSearch":fSearch,
            "fPortfolio":fPortfolio,
            "fPricing":fPricing,
            "fCTA":fCTA,
            "fForum":fForum,
            "fOthers":fOthers,
            "hosting":hosting,
            "migration":migration,
            "maintenance":maintenance,
            "facebook":facebook,
            "youtube":youtube,
            "instagram":instagram,
            "yelp":yelp,
            "otherLinks":otherLinks,
            "content":content,
            "phone":phone,
            "email":email,
            "adderss":adderss,
            "mailing":mailing,
            "zipcode":zipcode,
            "otherInfo":otherInfo
        }
    
        function handleSubmit(status, response){
            setLoading(false);
            if(status){
                if(response.code === 200){
                    setStep(6);
                    handleScroll()
                }else{
                    setError("Unknown Error.")
                }
            }else{
                setError("There was a problem submiting the form. Please try again later.");
            }
        }
    
        post("https://form.purplez.com/submit.php", data, handleSubmit)
    }

    const mainelement = document.getElementsByClassName('main')[0];
    function handleScroll(){
        if (mainelement) {
            mainelement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    var nodeRef = React.createRef(null);

    function NavbarTop(){

        if(step == 0){
            return(
                <div ref={nodeRef}>
                    <h2>Purplez Website Questionnaire</h2>
                    <p>Follow the steps to complete the form.</p>
                </div>);
        } else if(step == 1){
            return(
                <div ref={nodeRef}>
                    <h2>Step 1</h2>
                    <p>Enter your company information and help us know you better.</p>
                </div>);
        } else if(step == 2){
            return(
                <div ref={nodeRef}>
                    <h2>Step 2</h2>
                    <p>Enter your project information to get closer to companies.</p>
                </div>);
        } else if(step == 3){
            return(
                <div ref={nodeRef}>
                    <h2>Step 3</h2>
                    <p>Select how you like your website's appearance and options.</p>
                </div>);
        } else if(step == 4){
            return(
                <div ref={nodeRef}>
                    <h2>Step 4</h2>
                    <p>Enter your links and the source of the content of your website.</p>
                </div>);
        } else if(step == 5){
            return(
                <div ref={nodeRef}>
                    <h2>Step 5</h2>
                    <p>For the last step enter your contact information and enjoy our services.</p>
                </div>);
        } else {
            return(
                <div ref={nodeRef}>
                    <h2>Completed</h2>
                    <p>You have completed all the steps.</p>
                </div>);
        }
        
    }

    return(<div className="form">
        <Helmet>
            <title>Website Questionnaire - Purplez</title>
            <meta name="description" content="PurpleZ Website Questionnaire Form" />
        </Helmet>
        <div className="sidebar">
            <Link to="/" className="logo"/>
            <div className="navbar">
                <SwitchTransition>
                    <CSSTransition in={step} nodeRef={nodeRef} key={step} classNames="navtop" timeout={{enter: 200,exit: 200}}>
                        <NavbarTop/>
                    </CSSTransition>
                </SwitchTransition>
                <ul>
                    {step != 6 ? <li onClick={handleSetStep} id="1" className={step==0 ? "navbar-first" : "navbar-first active"}><span>1</span>Company Information</li> : <li id="1" className={step==0 ? "navbar-first" : "navbar-first active"}><span>1</span>Project Information</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="2" className={step>1 && "active"}><span>2</span>Project Information</li>: <li id="2" className={step>1 && "active"}><span>2</span>Design Information</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="3" className={step>2 && "active"}><span>3</span>Design and Development</li>: <li id="3" className={step>2 && "active"}><span>3</span>Content Source</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="4" className={step>3 && "active"}><span>4</span>Content Source</li>: <li id="4" className={step>3 && "active"}><span>4</span>Contact Info</li>}
                    {step != 6 ?<li onClick={handleSetStep} id="5" className={step>4 && "active"}><span>5</span>Contact Info</li>: <li id="5" className={step>3 && "active"}><span>4</span>Contact Info</li>}
                </ul>
            </div>
        </div>
        <div className="main">
            <SwitchTransition>
                <CSSTransition key={step} classNames="main" timeout={{enter: 200,exit: 200}}>
                    {step == 0?
                        <Main handleSetStep={handleSetStep}/>
                    : step == 1?
                        <Step1
                            handleSetStep={handleSetStep}
                            name={name} handleSetName={handleSetName}
                            DBA={DBA} handleSetDBA={handleSetDBA}
                            service={service} handleSetService={handleSetService}
                            apart={apart} handleSetApart={handleSetApart}
                            target={target} handleSetTarget={handleSetTarget}
                            website={website} handleSetWebsite={handleSetWebsite}
                            whyNewWebsite={whyNewWebsite} handleSetWhyNewWebsite={handleSetWhyNewWebsite}
                            branding={branding} handleSetBranding={handleSetBranding}
                            error={error}/>
                    : step == 2?
                        <Step2 
                            handleSetStep={handleSetStep}
                            keyword={keyword} handleSetKeyword={handleSetKeyword}
                            url={url} handleSetUrl={handleSetUrl}
                            deadline={deadline} handleSetDeadline={handleSetDeadline}
                            interests={interests} handleSetInterests={handleSetInterests}
                            similar={similar} handleSetSimilar={handleSetSimilar}
                            error={error}/>
                    : step == 3?
                        <Step3
                            handleSetStep={handleSetStep}
                            color1={color1} handleSetColor1={handleSetColor1}
                            color2={color2} handleSetColor2={handleSetColor2}
                            color3={color3} handleSetColor3={handleSetColor3}
                            logo={logo} handleSetLogo={handleSetLogo}
                            fForm={fForm} handleSetFForm={handleSetFForm}
                            fEcommerce={fEcommerce} handleSetFEcommerce={handleSetFEcommerce}
                            fMap={fMap} handleSetFMap={handleSetFMap}
                            fSocialMedia={fSocialMedia} handleSetFSocialMedia={handleSetFSocialMedia}
                            fCTC={fCTC} handleSetFCTC={handleSetFCTC}
                            fOnlineOrder={fOnlineOrder} handleSetFOnlineOrder={handleSetFOnlineOrder}
                            fSearch={fSearch} handleSetFSearch={handleSetFSearch}
                            fPortfolio={fPortfolio} handleSetFPortfolio={handleSetFPortfolio}
                            fPricing={fPricing} handleSetFPricing={handleSetFPricing}
                            fCTA={fCTA} handleSetFCTA={handleSetFCTA}
                            fForum={fForum} handleSetFForum={handleSetFForum}
                            fOthers={fOthers} handleSetFOthers={handleSetFOthers}
                            hosting={hosting} handleSetHosting={handleSetHosting}
                            migration={migration} handleSetMigration={handleSetMigration}
                            maintenance={maintenance} handleSetMaintenance={handleSetMaintenance}
                            error={error}/>
                    : step == 4?
                        <Step4
                            handleSetStep={handleSetStep}
                            facebook={facebook} handleSetFacebook={handleSetFacebook}
                            youtube={youtube} handleSetYoutube={handleSetYoutube}
                            Instagram={instagram} handleSetInstagram={handleSetInstagram}
                            yelp={yelp} handleSetYelp={handleSetYelp}
                            otherLinks={otherLinks} handleSetOtherLinks={handleSetOtherLinks}
                            content={content} handleSetContent={handleSetContent}
                            error={error}/>
                    : step == 5?
                        <Step5
                        handleSetStep={handleSetStep}
                        submit={submit}
                        loading={loading}
                        error={error}
                        phone={phone} handleSetPhone={handleSetPhone}
                        email={email} handleSetEmail={handleSetEmail}
                        adderss={adderss} handleSetAddress={handleSetAddress}
                        mailing={mailing} handleSetMailing={handleSetMailing}
                        zipcode={zipcode} handleSetZipcode={handleSetZipcode}
                        otherInfo={otherInfo} handleSetOtherInfo={handleSetOtherInfo}/>
                    :
                        <End handleSetStep={handleSetStep}/>
                    }
                </CSSTransition>
            </SwitchTransition>
        </div>
    </div>);
}

function Main(parms){
    return(<div>
        <div className="icon icon-waving"></div>
        <h1>Welcome To Purplez</h1>
        <p>In this form we are going to collect some important imformation about your website project so we can develop it in the best way. Please help us By filling the whole fields to undrstand your project's requirements.</p>
        <p>Thanks.</p>
        <button onClick={parms.handleSetStep} id="1">Let's Go</button>
    </div>);
}