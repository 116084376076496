import { Input } from "../../../components/input";

export default function Step1(parms){
    return(<div>
        <div className="icon icon-finger"></div>
        <h2>Business Information</h2>
        <p>Base information about your business.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.name === "") ? true : false} title="Client/Business Name" placeholder="Purplez" value={parms.name} handler={parms.handleSetName}/>
            <Input error={(parms.error !== "" && parms.DBA === "") ? true : false} title="DBA (Doing business as)" placeholder="if applicable" value={parms.DBA} handler={parms.handleSetDBA} col2/>
            <Input error={(parms.error !== "" && parms.phone === "") ? true : false} title="Phone Number(s)" placeholder="+1 (800) 213-7303" value={parms.phone} handler={parms.handleSetPhone}/>
            <Input error={(parms.error !== "" && parms.email === "") ? true : false} title="Email Address" placeholder="info@purplez.com" value={parms.email} handler={parms.handleSetEmail} col2/>
            <Input title="Do you currently have a website?"  placeholder="https://purplez.com" value={parms.website} handler={parms.handleSetWebsite} full/>
        </div>
        <span onClick={parms.handleSetStep} id="0" className="back">Back</span><button onClick={parms.handleSetStep} id="2">Next Step</button>
    </div>);
}