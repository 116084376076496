import { Input, Radiobox } from "../../../components/input";

export default function Step3(parms){
    return(<div>
        <div className="icon icon-writing"></div>
        <h2>Design and Media</h2>
        <p>Choose your desired design system.</p>
        {parms.error === " " && <p className="error">Please fill the required fields.</p>}
        <div className="form-container">
            <Input error={(parms.error !== "" && parms.font === "") ? true : false} title="Do you use a specific font?" placeholder="ex. Poppins" value={parms.font} handler={parms.handleSetFont}/>
            <Input error={(parms.error !== "" && parms.material === "") ? true : false} title="Do you have your marketing material on a cloud?" placeholder="If yes provide the link (video/photo)" value={parms.material} handler={parms.handleSetMaterial} col2/>
            <Input error={(parms.error !== "" && parms.color1 === "") ? true : false} title="Brand color #1" value={parms.color1} handler={parms.handleSetColor1} color/>
            <Input title="Brand color #2" value={parms.color2} handler={parms.handleSetColor2} color/>
            <Input title="Brand color #3" value={parms.color3} handler={parms.handleSetColor3} color col2/>
        </div>
        <span onClick={parms.handleSetStep} id="3" className="back">Back</span><button onClick={parms.handleSetStep} id="5">Next Step</button>
    </div>);
}